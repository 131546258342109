.container a {
    text-underline-offset: 0.2em;
    text-decoration-thickness: 1px;
    @apply underline text-blue-700 hover:text-blue-900 visited:text-blue-500;
}

.container ul {
    @apply list-disc list-outside ml-4;

}
.container li {
    @apply mb-4 
}